<template>
  <div>
    <b-card v-if="!invoice">
      <b-row>
        <b-col cols="12">
          <p style="text-align:justify">
            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; Dear Partner,
          </p>
          <p style="text-align:justify">
            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; The anti-fraud tool provided in the TrafficManager platform has an extra cost.
            Please choose the package that suits your needs from here, pay it, and the system will automatically add the credit to your platform.
          </p>
          <p style="text-align:justify">
            <center><b>Click on the desired package:</b></center>
          </p>
          <br>
        </b-col>
      </b-row>
      <b-card-group deck>
        <b-card
          v-for="item in packages"
          :key="item.id"
          :bg-variant="item.bgVariant"
          :text-variant="item.textVariant"
          class="text-center"
          header-class="item-headx"
          style="cursor: pointer;max-width:270px"
          @click="selectPackage(item)"
        >
          <b-card-text>
            <b>{{ item.package_name }}</b> <br> <br>
            <b>{{ item.credit }} credits</b> <br><br>
            Price € {{ item.price }}
          </b-card-text>
        </b-card>
      </b-card-group>
      <b-row>
        <b-col cols="12">
          <br>
          <b-button
            style="float:right; margin-bottom: 5px;"
            variant="primary"
            :disabled="subscribedNowBtn"
            @click="subscribedNow"
          >
            <span v-if="subscribedNowBtn">
              <b-spinner
                small
                type="grow"
              />
              Buying...
            </span>
            <span v-else>
              <feather-icon
                icon="CheckSquareIcon"
                size="12"
              />
              Buy now!
            </span>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <div v-else>
      <b-card>
        <b-card-group deck>
          <b-card
            style="max-width:270px"
            bg-variant="success"
            text-variant="white"
            border-variant="secondary"
            class="text-center"
            header-class="item-headx"
          >
            <b-card-text>
              <b>{{ invoice.package_name }}</b> <br> <br>
              <b>{{ invoice.credit }} credits</b> <br><br>
              Price € {{ invoice.price }}
            </b-card-text>
          </b-card>
        </b-card-group>
        <b-button
          v-if="invoice.invoice_status !== 'paid'"
          style="float:right; margin-bottom: 5px;"
          variant="primary"
          :disabled="resetBtn"
          @click="resetTransaction"
        >
          <span v-if="resetBtn">
            <b-spinner
              small
              type="grow"
            />
            Reseting...
          </span>
          <span v-else>
            <feather-icon
              icon="RewindIcon"
              size="12"
            />
            Reset
          </span>
        </b-button>
      </b-card>
      <b-row>
        <b-col cols="12">
          <!-- table -->
          <vue-good-table
            v-if="rows"
            :columns="columns"
            :rows="rows"
            :sort-options="{
              enabled: true,
            }"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Invoice No -->
              <span v-if="props.column.field === 'customInvoiceNo'">
                <router-link
                  :to="`/invoice/custom/${props.row.invoice_id}`"
                >
                  {{ props.row.customInvoiceNo }}
                </router-link>
              </span>

              <!-- Column: status -->
              <span v-else-if="props.column.field === 'invoice_status'">
                <b-badge
                  v-if="props.row.invoice_status === 'pending'"
                  variant="warning"
                >
                  {{ props.row.invoice_status }}
                </b-badge>
                <b-badge
                  v-else-if="props.row.invoice_status === 'paid'"
                  variant="success"
                >
                  {{ props.row.invoice_status }}
                </b-badge>
                <b-badge
                  v-else
                  variant="danger"
                >
                  {{ props.row.invoice_status }}
                </b-badge>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap mt-1">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10','25','50']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BButton, BSpinner, BCardGroup, BCardText,
  BPagination, BBadge, BFormSelect,
} from 'bootstrap-vue'
import axios from '@/libs/axios'
import moment from 'moment'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    VueGoodTable,
    BCard,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BCardGroup,
    BCardText,
    BPagination,
    BBadge,
    BFormSelect,
  },
  data() {
    return {
      resetBtn: false,
      packages: null,
      subscribedNowBtn: false,
      selectedPackage: null,
      invoice: null,
      invoiceLinkText: '',
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Invoice #',
          field: 'customInvoiceNo',
        },
        {
          label: 'Package',
          field: 'customPackage',
        },
        {
          label: 'Name',
          field: 'customUsername',
        },
        {
          label: 'Transaction Date',
          field: 'customIssueDate',
        },
        {
          label: 'Invoice Value',
          field: 'invoiceValue',
        },
        {
          label: 'Invoice Status',
          field: 'invoice_status',
        },
        {
          label: 'Credit',
          field: 'credit',
        },
        {
          label: 'Credit Status',
          field: 'customCreditStatus',
        },

      ],
      rows: [],
      searchTerm: '',
    }
  },
  mounted() {
    this.getInvoice()
    this.antiFraudPackage()
  },
  methods: {
    formatDate(Date) {
      return moment(Date).format('DD MMM YYYY')
    },
    readyInvoice(item) {
      let creditStatus = ''
      if (item.is_success_add_credit) creditStatus = 'Successfully added Anti-fraud Credits'
      else if (item.admin_domain) creditStatus = `Bad Domain Name (${item.admin_domain})`
      else creditStatus = 'Payment required'

      const info = {
        customInvoiceNo: `TMC${String(item.invoice_number_v2).padStart(4, '0')}`,
        customPackage: item.package_name,
        customUsername: `${item.forename} ${item.surname} (${item.email})`,
        customIssueDate: this.formatDate(item.created_at),
        invoiceValue: `${item.currency} ${item.total}`,
        invoice_status: item.invoice_status,
        invoice_id: item.invoice_id,
        uid: item.uid,
        credit: item.credit,
        customCreditStatus: creditStatus,
      }
      this.rows.push(info)
    },
    resetTransaction() {
      // this.resetBtn = true
      const payload = {
        antiFraudUserID: this.invoice.anti_fraud_user_id,
      }
      const uri = '/api/antifraud/reset_transaction'
      axios.post(uri, payload).then(() => {
        this.$router.go()
        this.resetBtn = false
      })
    },
    getInvoice() {
      const uri = '/api/antifraud/get_invoice'
      axios.get(uri).then(res => {
        this.invoice = res.data
        this.readyInvoice(this.invoice)
      })
    },
    antiFraudPackage() {
      const uri = '/api/antifraud/get_packages'
      axios.get(uri).then(res => {
        this.packages = res.data
        for (const item of this.packages) { // eslint-disable-line
          item.bgVariant = 'light' // eslint-disable-line
          item.textVariant = 'black' // eslint-disable-line
        }
      })
    },
    selectPackage(item) {
      for (const i of this.packages) { // eslint-disable-line
        if (item.id === i.id) {
            i.bgVariant = 'success' // eslint-disable-line
            i.textVariant = 'white' // eslint-disable-line
        } else {
            i.bgVariant = 'light' // eslint-disable-line
            i.textVariant = 'black' // eslint-disable-line
        }
        this.methodThatForcesUpdate()
        this.selectedPackage = item
      }
    },

    methodThatForcesUpdate() {
      this.$forceUpdate() // Notice we have to use a $ here
    },

    async subscribedNow() { // eslint-disable-line
      if (!this.selectedPackage) {
        this.notify({
          text: 'Please select a package.',
          variant: 'danger',
        })
        return false
      }

      this.subscribedNowBtn = true
      const payload = {
        id: this.selectedPackage.id,
      }
      const uri = 'api/antifraud/subscribe'
      await axios.post(uri, payload).then(res => { // eslint-disable-line
        this.notify({
          text: 'Thank you. Your invoice was generated.',
          variant: 'success',
        })
        const invoiceID = res.data.invoice_id
        this.$router.push(`/invoice/custom/${invoiceID}`)
      })
      this.subscribedNowBtn = false
      // this.$router.go()
    },

    // just an alias
    s(x) {
      return this.numberToString(x)
    },
    numberToString(x) {
      const str = this.suffixNumberString(x)
      let numStr = x.toString() // eslint-disable-line
      if (str === 'million') numStr = `${numStr.slice(0, -6)} ${str}` // eslint-disable-line
      else if (str === 'thousand') numStr = `${numStr.slice(0, -3)} ${str}` // eslint-disable-line
      return numStr
    },
    suffixNumberString(x) { // eslint-disable-line
      if (x >= 1000000 && x <= 999999999) return 'million' // eslint-disable-line
      else if (x >= 1000  && x <= 999999) return 'thousand' // eslint-disable-line
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';

.invoice-table {
  thead tr{
    background: #f3f2f7;
  }

  th, td {
    font-size: 14px;
    text-align: center !important;
    vertical-align: middle !important;
  }
}

.item-headx{
  text-align: center !important;
  font-weight: bold;
}
</style>

<style lang="scss" scoped>
.dark-layout .bg-light {
  background: rgba(24, 31, 51, 0.62) !important;
}
</style>
